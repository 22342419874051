@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&family=Raleway:wght@300;400;500;600&display=swap");

:root {
  --primary: #0c2e8a;
  --success: #50d8af;
  --secondary: #555;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

*,
*:hover,
*:active {
  box-sizing: border-box;
  transition: 0.25s ease all;
  outline: none;
}


img {
  /* width: fit-content; */
  /* height: auto; */
}

._coverImage-holder {
  width: 600px;
  /* height: 300px; */
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

._coverImage {
  border: 1px solid #555;
  object-fit: cover;
  display: inline;
  margin: 0 auto;
  height: 300px;
  width: 600px;
}

._coverImage-icon {
  position: absolute;
  bottom: 20px;
  right: 10px;
  color: blue;
  font-size: 20px;
}

._coverImage-crop {
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
  font-size: 18px;
}

h3 {
  padding: 0;
  margin: 0;
}

.article-avatar {
  object-fit: cover;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #000;
  justify-self: center;
}

.comment-avatar {
  object-fit: cover;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #000;
  justify-self: center;
}

.comment-body {
  display: flex;
}

.avatar-container {
  display: flex;
  justify-content: center;
  margin: 10px;
  padding: 10px;
}

.body-container {
  flex:  2;
  margin: 10px;
  padding: 10px;
}

.react-switch {
  /* max-height: 25px; */
}





a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

._row {
  display: flex;
  align-items: center;
}

._row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

a.link,
.link {
  color: var(--secondary) !important;
}
a.link:hover,
.link:hover {
  color: var(--success) !important;
}

/* img {
  width: 100%;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

option {
  color: var(--secondary);
}

/* [[
  Animations
]] */

/* input */
._input ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--success);
  transition: 0.4s;
}
._input:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}

._textArea ~ .focus-border:before,
._textArea ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--success);
  transition: 0.3s;
}
._textArea ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
._textArea ~ .focus-border i:before,
._textArea ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: var(--success);
  transition: 0.4s;
}
._textArea ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
._textArea:focus ~ .focus-border:before,
._textArea:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
._textArea:focus ~ .focus-border i:before,
._textArea:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}

._mt-lg {
  margin-top: 120px;
}

._mt-md {
  margin-top: 80px;
}

._mt-sm {
  margin-top: 40px;
}

/* [[
  Profile
]] */

.profile-holder {
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  /* border-radius: 50%;
  border: 1px solid var(--success); */
}

._profilePicture {
  /* border: 1px solid var(--success); */
  object-fit: cover;
  display: inline;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 1px solid var(--success);

}

._avatar {
  object-fit: cover;
  display: inline;
  margin: 0 auto;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  padding: 2px 2px;
}

._avatar_holder {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
}

._profilePicture-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: var(--secondary);
  font-size: 20px;
}

.swal2-popup.swal2-toast.swal2-icon-success {
  background: #28a746;
}

.swal2-popup.swal2-toast.swal2-icon-error {
  background: #dc3545;
}

.swal2-popup.swal2-toast .swal2-title {
  color: #fff;
}


@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(0.95, 0.95, 0.95);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.fadeInLeft {
  animation: 0.9s ease-in-out fadeInLeft 1 forwards;
}




.main-press-releases {
  font-family: Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  color: #000;
  position: relative;
}

.main-press-releases__header {
  margin-bottom: 14px;
}

.main-press-releases__header > a {
  text-transform: none;
  font-weight: 600;
  font-size: 1.125rem;
  color: #465964;
  text-decoration: none;
}

.main-press-releases__wrp {
  /* border: 3px dotted #9B1217; */
  /* border: 1px solid #fff; */
  box-sizing: border-box;
  border-radius: 5px;
  overflow-x: hidden;
  box-shadow: 0 1px 9px rgba(0,0,0,.26);
  z-index: 0;
}

.main-press-releases__wrp_mobile {
  box-shadow: none!important;
}

.main-press-releases__list {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-press-releases__item {
  width: 33.33333%;
  margin-bottom: 0;
  padding: 16px;
  border-bottom: 0;
  opacity: 1;
  will-change: transform;
}

.main-press-releases__item > a:hover {
  text-decoration: underline;
}

.main-press-releases__link {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: #27272d;
  font-weight: 800;
}

.main-press-releases__figure {
  width: 130px;
  height: 100px;
  margin-right: 8px;
  flex-shrink: 0;
  display: block;
  margin: 0 18px 0 0;
  overflow: hidden;
  border-radius: 3px;
}

.main-press-releases__cover {
  padding-top: 100%;
  position: relative;
  display: block;
  /* width: 200px; */
  height: 0;
}

.main-press-releases__arrow-btn {
  display: block;
  /* align-items: center;
  justify-content: center; */
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 0 1px 9px rgba(0,0,0,.26);
  transition: transform .15s;
  z-index: 20000;
  border-radius: 50%;
  background-color: #9B1217;
  border: 1px solid #9B1217;
}

.main-press-releases__arrow-btn_left {
  left: -16px;
}

.main-press-releases__arrow-btn_right {
  right: -16px;
}

.btn { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
  text-align: inherit;
  font: inherit;
  color: inherit;
  background: #9B1217;
  appearance: none;
  cursor: pointer;
}

.btn, .input:focus {
  outline: none!important;
}

.btn__wrp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.main-press-releases__prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128' fill='%23fff'%3E%3Cpath d='M45 64l47.47 47.46a4.48 4.48 0 11-6.33 6.33L35.52 67.17a4.48 4.48 0 010-6.33l50.63-50.63a4.48 4.48 0 116.33 6.33z'/%3E%3C/svg%3E");
}

.main-press-releases__next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128' fill='%23fff'%3E%3Cpath d='M83 64L35.52 16.54a4.48 4.48 0 116.33-6.33l50.63 50.62a4.48 4.48 0 010 6.33l-50.63 50.63a4.48 4.48 0 11-6.33-6.33z'/%3E%3C/svg%3E");
}

.main-press-releases__prev-icon, .main-press-releases__next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.btn__icon {
  display: block;
  width: 14px;
  height: 14px;
  fill: currentColor;
}

.lazy-image_loaded .lazy-image__img {
  background-color: transparent;
  opacity: 1;
}

.lazy-image__img, .lazy-image__pending {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  /* width: 100%;
  height: 100%; */
  transition: opacity .15s;
}

.lazy-image__img {
  object-fit: cover;
}

@keyframes slideInRight {
  0% {
    transform: translate3d(7%, 0, 0);
    visibility: visible;
  }
  

  100% {
    transform: translate3d(-7%, 0, 0);
  }
}
  

.slideInRight {
  animation: slideInRight 0.15s ease-in-out 0s 1 normal;
}

@media (max-width: 990px) {
  .main-press-releases__wrp {
    border: none;
  }

  .main-press-releases__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .main-press-releases__item {
    width: 100%;
    padding-left: 0px;
    /* border-bottom: 1px solid #91a1aa; */
  }

  .main-press-releases__figure {
    width: 100px;
    height: 60px;
  }

  .lazy-image__img, .lazy-image__pending {
    height: 60%;
  }

  .main-press-releases__title {
    font-size: .8125rem;
    font-weight: 600;
  }

  .slideInRight {
    animation: none;
  }

  .btn { 
    display: none;
  }

  .load-hide {
    display: flex;
    justify-content: space-between;
    color: #9B1217;
    margin-top: 1rem;
  }
  
  .load-icon {
    font-weight: 900;
    font-size: 1.05rem;
  }

  .load-more, .hide {
    background: transparent;
    border: none;
    color: #9B1217;
    cursor: pointer;
  }

  .load-more, .hide, .input:focus {
    outline: none!important;
  }

  .hide {
    margin-left: auto;
  }
}

.ReactTags__tags {
  position: relative;
  margin-top: 10px;
  margin-bottom: 40px;
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  /* display: inline-block; */
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

._editor_inputs {
  width: 100%;
  border: 2px #c4c4c4 solid;
  margin-bottom: 15px;
  padding: 10px;
}